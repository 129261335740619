<template>
  <div>
    <b-card-code title="Add Status" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- Name -->

            <!-- Name english -->
            <b-col md="6">
              <b-form-group label="Name English" label-for="Name English">
                <validation-provider
                  #default="{ errors }"
                  name="Name English"
                  rules="required"
                >
                  <b-form-input
                    id="Name-English"
                    v-model="data.name.en"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Name Arabic -->
            <b-col md="6">
              <b-form-group label="Name Arabic" label-for="Name Arabic">
                <validation-provider
                  #default="{ errors }"
                  name="Name Arabic"
                  rules="required"
                >
                  <b-form-input
                    id="Name-Arabic"
                    v-model="data.name.ar"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <hr />
            </b-col>
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
export default {
  components: {
    BCardCode,
  },
  mixins: [heightTransition],
  data() {
    return {
      showSpinnerLoad: false,

      data: {
        name: {
          en: '',
          ar: '',
        },
      },
      errors_back: [],
      showDismissibleAlert: false,
      required,
    }
  },

  methods: {
    // submit Category API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios
            .post('order-status', this.data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push({ name: 'orderStatus' })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    // REPETED FORM
    repeateAgain() {
      this.data.status.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
    },
    // remove function to delete single translation from category
    removeItem(index) {
      if (this.data.status.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.data.status.splice(index, 1)
      }
    },
  },
}
</script>

<style lang="scss">
 
</style>
